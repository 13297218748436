// firebaseHelpers.tsx
import { doc, getDoc, getDocs, setDoc, collection, writeBatch, DocumentSnapshot } from 'firebase/firestore';
import db from './firestoreDb';
import { debounce } from 'lodash';
import localForage from 'localforage';
import { Chat, ChatMessage } from '../components/BaseChatElements/chatUtils';
import { IDoc } from '../components/DocumentSearch/DocumentTypes';
import { TextInput } from '../components/ModuleDocumentGenerate/DocGenContentInput';

const DEBOUNCING_MIN_TIMEOUT = 2000;
type CachedItem = { value: any };
const ongoingRequests: { [cacheKey: string]: boolean } = {};
//const debouncedSetDoc = debounce(setDoc, DEBOUNCING_MIN_TIMEOUT); // Debounce Firestore writes
const debouncedSetDocMap: { [cacheKey: string]: any } = {};

const getDebouncedSetDoc = (cacheKey: string) => {
	if (!debouncedSetDocMap[cacheKey]) {
		debouncedSetDocMap[cacheKey] = debounce((docRef, updatedDocument) => {
			setDoc(docRef, updatedDocument, { merge: true })
				.catch(error => {
					console.error('Error updating data:', error);
				});
		}, DEBOUNCING_MIN_TIMEOUT);
	}
	return debouncedSetDocMap[cacheKey];
};

export const clearLocalCache = async () => {
	await localForage.clear();
};

const getDocumentFromCache = async (cacheKey: string) => {
	const cachedItem = await localForage.getItem<CachedItem>(cacheKey);
	return cachedItem?.value || null;
};

const setDocumentInCache = async (cacheKey: string, data: any) => {
	await localForage.setItem(cacheKey, { value: data });
};

const generateCacheKey = (userId: string, entity: string, key: string = ''): string => {
	return `${entity}_${userId}_${key}`;
};

const retrieveFromCache = async (userId: string, entity: string, key: string = ''): Promise<any | null> => {
	const cacheKey = generateCacheKey(userId, entity, key);
	return await getDocumentFromCache(cacheKey);
};

const fetchDataFromFirebase = async (userId: string, entity: string, key: string = ''): Promise<any> => {
	let docRef;

	if (entity === 'projects') {
		docRef = doc(db, 'users', userId, entity, key);
	} else {
		docRef = doc(db, 'users', userId);
	}

	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		return null;
	}
};

const waitForOngoingRequest = async (cacheKey: string): Promise<any | null> => {
	while (ongoingRequests[cacheKey]) {
		await new Promise((resolve) => setTimeout(resolve, 50)); // 50ms delay
		const cachedDocument = await getDocumentFromCache(cacheKey);
		if (cachedDocument) {
			return cachedDocument;
		}
	}
	return null;
};

export const fetchData = async (userId: string, entity: string, key: string = ''): Promise<any> => {
	if (!userId || !entity) return null;

	let cachedDocument = await retrieveFromCache(userId, entity, key);
	if (cachedDocument) {
		return cachedDocument;
	}

	const cacheKey = generateCacheKey(userId, entity, key);
	cachedDocument = await waitForOngoingRequest(cacheKey);
	if (cachedDocument) {
		return cachedDocument;
	}

	// Mark this cacheKey as having an ongoing request.
	ongoingRequests[cacheKey] = true;

	const data = await fetchDataFromFirebase(userId, entity, key);

	if (data) { await setDocumentInCache(cacheKey, data); }

	// Remove the cacheKey from ongoing requests.
	delete ongoingRequests[cacheKey];

	return data;

	// if (data) {
	// 	//await setDocumentInCache(cacheKey, data);
	// 	return data;
	// } else {
	// 	return null;
	// }
};

let updateQueue: any[] = [];
let isProcessing = false;
export const updateDataToFirebase = async (userId: string, entity: string, data: any, key: string = '') => {
	//if (!userId || !project || !stateKey || !state) return;
	if (!userId || !entity) return;
	// if (!stateKey) {
	// 	let aaa = 1;
	// }
	updateQueue.push({ userId, entity, data, key });
	processQueue();
};
const processQueue = async () => {
	if (isProcessing) return;
	isProcessing = true;
	while (updateQueue.length) {
		const { userId, entity, data, key } = updateQueue.shift();
		await updateDataToFirebase_Internal(userId, entity, data, key);
	}
	isProcessing = false;
};

const updateDataToFirebase_Internal = async (userId: string, entity: string, data: any, key: string = '') => {
	const cacheKey = generateCacheKey(userId, entity, key);
	let cachedDocument = await getDocumentFromCache(cacheKey);

	if (!cachedDocument) {
		await fetchData(userId, entity, key); // Cache the document in local storage
		cachedDocument = await getDocumentFromCache(cacheKey);
	}
	let updatedDocument = { ...cachedDocument, ...data, };

	if (!cachedDocument || JSON.stringify(cachedDocument) !== JSON.stringify(updatedDocument)) {
		// Update the cache
		updatedDocument = {
			...cachedDocument,
			...data,
			date_last_modified: new Date()
		};
		await setDocumentInCache(cacheKey, updatedDocument);

		let docRef;
		if (entity === 'projects') {
			docRef = doc(db, 'users', userId, entity, key);
		} else {
			docRef = doc(db, 'users', userId);
		}

		try {
			const debouncedSetFunction = getDebouncedSetDoc(cacheKey);
			await debouncedSetFunction(docRef, updatedDocument);
			//await debouncedSetDoc(docRef, updatedDocument, { merge: true });
		} catch (error) {
			console.error('Error updating data:', error);
		}
	}
};

// Selected Judgement functions ========================================

function cleanupUrlForFirestoreId(url: string) {
	// 1. Basic Encoding:
	const encodedUrl = encodeURIComponent(url);

	// 2. Replace Slashes:
	const idWithDashes = encodedUrl.replace(/%2F/g, '-');

	// 3. Remove Periods:
	const idWithoutPeriods = idWithDashes.replace(/%2E/g, '');

	// 4. (Optional) Additional Replacements:
	//You can add more replacements if needed, for example:  
	const id = idWithoutPeriods.replace(/%5B/g, '_').replace(/%5D/g, '_'); // Brackets

	return id;
}
function removeEmptyPropertyNames(obj: any) {
	for (const prop in obj) {
		if (obj.hasOwnProperty(prop)) {
			if (prop === '') {
				delete obj[prop];
			} else if (typeof obj[prop] === 'object') {
				removeEmptyPropertyNames(obj[prop]);
			}
		}
	}
}

// ===============  DocGen InputText functions ========================================
export const firestoreDocGenSaveInputText = async (userId: string, projectId: string, t: TextInput) => {
	const docRef = doc(db, 'users', userId, 'projects', projectId, 'DocGenInputTexts', t.id);
	await setDoc(docRef, t, { merge: true })
		.catch(error => {
			console.error('Error saving DocGenInputText:', error);
		});
}

export const firestoreDocGenLoadInputTexts = async (userId: string, projectId: string) => {
	const my_docs: TextInput[] = [];
	const querySnapshot = await getDocs(collection(db, 'users', userId, 'projects', projectId, 'DocGenInputTexts'));
	querySnapshot.forEach((doc) => {
		my_docs.push(doc.data() as TextInput);
	});
	return my_docs;
}

export const firestoreDocGenDeleteInputText = async (userId: string, projectId: string, t: TextInput) => {
	const tRef = doc(db, 'users', userId, 'projects', projectId, 'DocGenInputTexts', t.id);
	const batch = writeBatch(db);
	batch.delete(tRef);
	await batch.commit()
		.catch(error => {
			console.error('Error deleting DocGenInputText:', error);
		});
}

// ===============  Marked Judgement functions ========================================
export const firestoreSaveMarkedJudgement = async (userId: string, projectId: string, d: IDoc) => {
	if (!!userId && !!projectId) {
		// Step 1: Creating a copy of d without the 'piecesWithGeometry' property.
		const { piecesWithGeometry, ...dWithoutGeometry } = d;
		removeEmptyPropertyNames(dWithoutGeometry);

		// Step 2: Save the modified copy to Firestore.
		const id = cleanupUrlForFirestoreId(d.url);
		const docRef = doc(db, 'users', userId, 'projects', projectId, 'markedJudgements', id);
		await setDoc(docRef, dWithoutGeometry, { merge: true })
			.catch(error => {
				console.error('Error saving markedJudgement:', error);
			});
	}
}

export const firestoreLoadMarkedJudgements = async (userId: string, projectId: string) => {
	if (!!userId && !!projectId) {
		const my_docs: IDoc[] = [];
		const querySnapshot = await getDocs(collection(db, 'users', userId, 'projects', projectId, 'markedJudgements'));
		querySnapshot.forEach((doc) => {
			my_docs.push(doc.data() as IDoc);
		});
		return my_docs;
	} else {
		return [];
	}
}

export const firestoreDeleteMarkedJudgement = async (userId: string, projectId: string, d: IDoc) => {
	if (!!userId && !!projectId) {
		const id = cleanupUrlForFirestoreId(d.url);
		const judgementRef = doc(db, 'users', userId, 'projects', projectId, 'markedJudgements', id);
		const batch = writeBatch(db);
		batch.delete(judgementRef);
		await batch.commit()
			.catch(error => {
				console.error('Error deleting marked judgement:', error);
			});
	}
}


// Chat functions ========================================
export const firestoreSaveChat = async (userId: string, projectId: string, chat: Chat) => {
	const docRef = doc(db, 'users', userId, 'projects', projectId, 'chats', chat.id);
	await setDoc(docRef, chat, { merge: true })
		.catch(error => {
			console.error('Error saving chat:', error);
		});
}

export const firestoreSaveChatMessage = async (userId: string, projectId: string, chatId: string, messageId: string, message: ChatMessage) => {
	let docRef = doc(db, 'users', userId, 'projects', projectId, 'chats', chatId, 'messages', messageId);
	await setDoc(docRef, message, { merge: true })
		.catch(error => {
			console.error('Error saving chat message:', error);
		});
}

export const firestoreLoadChat = async (userId: string, projectId: string, chatId: string): Promise<any> => {
	const docSnap = await getDoc(doc(db, 'users', userId, 'projects', projectId, 'chats', chatId));
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		return null;
	}
}

export const firestoreLoadChats = async (userId: string, projectId: string) => {
	const chats: Chat[] = [];
	const querySnapshot = await getDocs(collection(db, 'users', userId, 'projects', projectId, 'chats'));
	querySnapshot.forEach((doc) => {
		chats.push(doc.data() as Chat);
	});
	return chats;
}

export const firestoreLoadChatMessages = async (userId: string, projectId: string, chatId: string) => {
	const messages: ChatMessage[] = [];
	const querySnapshot = await getDocs(collection(db, 'users', userId, 'projects', projectId, 'chats', chatId, 'messages'));
	querySnapshot.forEach((doc) => {
		messages.push(doc.data() as ChatMessage);
	});
	return messages;
}

export const firestoreDeleteChat = async (userId: string, projectId: string, chatId: string) => {
	const chatRef = doc(db, 'users', userId, 'projects', projectId, 'chats', chatId);
	const messagesRef = collection(chatRef, 'messages');

	// First, delete all the messages associated with the chat
	const messagesSnapshot = await getDocs(messagesRef);
	const batch = writeBatch(db);
	messagesSnapshot.forEach((messageDoc) => {
		batch.delete(messageDoc.ref);
	});

	// Now, delete the chat itself
	batch.delete(chatRef);

	// Commit the batch
	await batch.commit()
		.catch(error => {
			console.error('Error deleting chat and its messages:', error);
		});
}
